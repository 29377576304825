import React, { Fragment, useCallback } from "react"
import { Page, Button, Badge } from "@shopify/polaris"
import { navigate } from "gatsby"
import formatLastSavedDate from "../helpers/formatLastSavedDate"
import {updateRule} from '../helpers/updateRule';
import { updateCustomization } from '../helpers/updateCustomization';
import update from 'immutability-helper';
import styled from 'styled-components';
const PageHeaderWrapper = styled.div`
  .Polaris-Page-Header--mediumTitle {
    width: 622.66px;
  }
  .Polaris-Page-Header--longTitle {
    width: 622.66px;
  }
  .Polaris-Page-Header__Row {
    width: 622.66px;
  }
`
const DocStatus = (props) => {
  const {
    children,
    isEditing,
    location,
    name,
    updatedAt,
    handleFormSubmit,
    isSubmitting,
    hasUnsavedChanges,
    token,
    shop,
    firebase,
    state,
    setState,
    domain,
    fullWidth,
    title,
    windowDimensions,
  } = props

  const handleGoToShipping = useCallback(() => {
    window.open(
      `https://${domain}/admin/settings/shipping#local-pickup`,
      "_blank"
    )
  }, [domain])
  const handleGoToPayment = useCallback(() => {
    window.open(
      `https://${domain}/admin/settings/payments/customizations`,
      "_blank"
    )
  }, [domain])

  const toggleEnabled = (rule) => {
    if("custom content" === rule.type || "custom field" === rule.type || "address blocker" === rule.type){
      updateCustomization({
        id: rule.id,
        enabled: !rule.enabled
      }, shop, firebase);
    }else{
    updateRule({
        id: rule.id,
        enabled: !rule.enabled
      },
      token, shop, firebase);
    }
  };

  const clickHandler = (e) => {
    e.stopPropagation();
    toggleEnabled(state);

    setState(update(state, { enabled: { $set: !state.enabled }}))
  }

  return isEditing ? (
    windowDimensions?.width  && windowDimensions?.width >= 1290 ?
    <PageHeaderWrapper>
        <Page
          title={name}
          subtitle={formatLastSavedDate(updatedAt)}
          titleMetadata={hasUnsavedChanges ? <Badge>Unsaved Changes</Badge> : (state.enabled ? <Badge tone="success">Live</Badge> : <Badge tone="info">Draft</Badge>)}
          primaryAction={"Hide payment method" === title ||
                      "Hide delivery method" === title ? (
                        <Button
                          onClick={() => {
                            if ("Hide payment method" === title) {
                              handleGoToPayment()
                            } else {
                              handleGoToShipping()
                            }
                          }}
                        >
                          To customizations page
                        </Button>) :
            (state.enabled ? <Button onClick={(e) => clickHandler(e)}>Turn off</Button> : <Button variant="primary" onClick={(e) => clickHandler(e)}>Turn on</Button>)
          }
          backAction={{
            onAction: () => {
              navigate("/app/customizations", {
                state: location.state,
                replace: true,
              })
            }}} 
        >
        {children}
      </Page>
      </PageHeaderWrapper>
      :
      <Page
      title={name}
      subtitle={formatLastSavedDate(updatedAt)}
      titleMetadata={hasUnsavedChanges ? <Badge>Unsaved Changes</Badge> : (state.enabled ? <Badge tone="success">Live</Badge> : <Badge tone="info">Draft</Badge>)}
      primaryAction={"Hide payment method" === title ||
                  "Hide delivery method" === title ? (
                    <Button
                      onClick={() => {
                        if ("Hide payment method" === title) {
                          handleGoToPayment()
                        } else {
                          handleGoToShipping()
                        }
                      }}
                    >
                      To customizations page
                    </Button>) :
        (state.enabled ? <Button onClick={(e) => clickHandler(e)}>Turn off</Button> : <Button variant="primary" onClick={(e) => clickHandler(e)}>Turn on</Button>)
      }
      backAction={{
        onAction: () => {
          navigate("/app/customizations", {
            state: location.state,
            replace: true,
          })
        }}} 
    >
      {children}
    </Page>
    ) : 
    (
      <Page
      title = {title ? title : "Create Customization"}
      >
      {children}
    </Page>
    )
}

export default DocStatus
